import React, { useState, useRef, useEffect } from 'react';
import s from './style.module.scss';
import i18next from 'i18next';

const SelectComponent = (props) => {
	const {
		options,
		value,
		onChange,
		className,
		placeholder,
		disabled,
		isMulti,
		overRideValueKey,
		overRideLabelKey,
		customPropsType,
	} = props;

	let dropDown = null;

	const [isOpen, setIsOpen] = useState(false);
	const [selectedValues, setSelectedValues] = useState(value || []);
	const [selectedLabels, setSelectedLabels] = useState(value || []);
	const [isAllClicked, setIsAllClicked] = useState(true);
	const [showLable, setShowLable] = useState(false);
	const [singleSelectedLable, setSingleSelectedLable] = useState(
		props.selectedLabel,
	);

	const selectRef = useRef(null);
	const handleCheckboxChange = (optionValue, optionLabel, option) => {
		if (optionValue === 'All') {
			setSelectedValues([]);
			setSelectedLabels([]);
			setIsAllClicked(true);
			setShowLable(false);
			onChange([]);
		} else {
			const updatedValues = selectedValues.includes(optionValue)
				? selectedValues.filter((val) => val !== optionValue)
				: [...selectedValues, optionValue];
			setSelectedValues(updatedValues);
			const updatedLabels = selectedLabels.includes(optionLabel)
				? selectedLabels.filter((val) => val !== optionLabel)
				: [...selectedLabels, optionLabel];

			setSelectedLabels(updatedLabels);
			setShowLable(true);
			setIsAllClicked(false);
			if (customPropsType === 'providers') {
				onChange(option, customPropsType);
			} else {
				onChange(updatedValues);
			}
		}
	};

	const handleToggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleSingleItemSelect = (optionValue, optionLabel) => {
		if (onChange && value !== optionValue) {
			onChange(optionValue);
			setSingleSelectedLable(optionLabel);
			setIsOpen(false);
		}
	};

	const handleOutsideClick = (event) => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};
	// Add an event listener to close the dropdown when clicking outside
	React.useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	useEffect(() => {
		onChange(selectedValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValues]);
	dropDown = (
		<div
			className={`${s.selectContainer} ${[className].join(' ')}`}
			ref={selectRef}
		>
			<div
				className={`${s.select} ${isOpen ? s.open : ''}`}
				onClick={handleToggleDropdown}
			>
				{isMulti && isMulti === true ? (
					<div className={s.selectedValues}>
						{showLable && selectedValues.length > 0
							? selectedLabels.join(',')
							: placeholder}
					</div>
				) : (
					<div className={s.selectedValues}>
						{value ? (singleSelectedLable || placeholder) : i18next.t('select_one')}
					</div>
				)}

				<div className={s.arrowIcon}>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						{isOpen ? (
							<path d='M17 14L12 9L7 14L17 14Z' fill='#6D6D6D' />
						) : (
							<path d='M7 10L12 15L17 10H7Z' fill='#999999' />
						)}
					</svg>
				</div>
			</div>
			{isOpen && (
				<div className={s.optionsContainer}>
					{isMulti && isMulti === true && (
						<>
							<div
								className={`${s.dropDownListItemContainer} ${s.borderBottom}`}
							>
								<div className={s.optionIcon}>
									<input
										type='checkbox'
										value={'all'}
										name='all'
										checked={isAllClicked}
										onChange={() => handleCheckboxChange('All', 'All')}
										disabled={disabled}
									/>
								</div>
								<div className={s.optionLabel}>
									<div
										key={-1}
										className={
											isAllClicked ? s.optionLabelAll : s.selectedOption
										}
									>
										{i18next.t('all')}
									</div>
								</div>
							</div>
						</>
					)}
					<div className={s.dropdownOptions}>
						{options.map((option) => {
							let optValue = overRideValueKey
								? option[overRideValueKey]
								: option.value;
							let optLabel = overRideLabelKey
								? option[overRideLabelKey]
								: option.label;
							return isMulti && isMulti === true ? (
								<>
									<div className={s.dropDownListItemContainer}>
										<div className={s.optionIcon}>
											<input
												type='checkbox'
												name='selectedOptions'
												value={optValue}
												checked={
													isAllClicked
														? false
														: selectedValues.includes(optValue)
												}
												onChange={() =>
													handleCheckboxChange(optValue, optLabel, option)
												}
												disabled={disabled}
											/>
										</div>
										<div className={s.optionLabel}>
											<label key={optValue} className={s.optionLabel}>
												{optLabel}
											</label>
										</div>
									</div>
								</>
							) : (
								<div
									key={optValue}
									// eslint-disable-next-line eqeqeq
									className={`${s.optionLabel} ${
										value === optValue ? s.selectedState : ''
									}`}
									onClick={() => handleSingleItemSelect(optValue, optLabel)}
								>
									{optLabel}
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);

	return <>{dropDown}</>;
};

export default SelectComponent;
