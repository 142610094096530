import i18next from 'i18next';

const messages = {
	userCreated: i18next.t('user_was_succesfully_created'),
	providerCreated: i18next.t('provides_successfully_created'),
	providerDeleted: i18next.t('provider_successfully_deleted'),
	providerSaved: i18next.t('changes_succesfully_saved'),
	notificationSent: i18next.t('push_notification_has_been_sent'),
	pinCompleted: i18next.t('provider_code_successfully_completed'),
	pinCreated: i18next.t('pin_code_successfully_created'),
	pinDeleted: i18next.t('pin_code_successfully_deleted'),
	resolved: i18next.t('resolved_all_errors_successfully'),
	wentWrong: i18next.t('something_went_wrong'),
	resolvedSelected: i18next.t('resolved_selected_errors_successfully'),
	oopsWentWrong: i18next.t('oops_something_went_wrong'),
	reportDownloadCompleted: i18next.t('report_download_completed'),
	reportDownloadStart: i18next.t('report_download_start'),
	deviceInfoError: i18next.t('device_info_messages_error'),
	userUpdated: i18next.t('user_updated_succesfully'),
	providerBlocked: i18next.t('provider_blocked_succesfully'),
	providerUnblocked: i18next.t('provider_unblocked_succesfully'),
	fetchSuccess: i18next.t('fetch_success'),
};

export default messages;
