import { coreInstance as axios, api, messages } from 'settings';
import { logger, toast } from 'helpers';
import * as actionTypes from './actionTypes';

export const getPatientsInfoStart = () => {
	return {
		type: actionTypes.GET_PATIENT_INFO_START,
	};
};

export const getPatientsInfo = (args = {}) => {
	const { params } = args;

	return async (dispatch) => {
		dispatch(getPatientsInfoStart());
		try {
			const response = await axios.get(`${api.patient_info.index}`, { params });
			dispatch(getPatientsInfoSuccess(response.data));
			return response.data; // Return data for further use
		} catch (err) {
			const res = {
				success: true,
				data: { total_rows: '', data: [] },
				message: err.message,
			};
			dispatch(getPatientsInfoSuccess(res.data));
			logger(`[Error] Failed fetching Patient Info Data" ${err}`);
			toast.error(messages.wentWrong, {
				style: { fontSize: '16px' },
			});
			throw err; // Throw error for further handling
		}
	};
};

export const getPatientsInfoSuccess = (data) => {
	return {
		type: actionTypes.GET_PATIENT_INFO_SUCCESS,
		data: data,
	};
};
