import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import concentratorsReducer from './reducers/concentrators';
import providersReducer from './reducers/providers';
import reportsReducer from './reducers/reports';
import pushNotificationsReducer from './reducers/pushNotifications';
import manageUsersReducer from './reducers/manageUsers';
import firmwareReducer from './reducers/firmware';
import rolesReducer from './reducers/roles';
import loadingReducer from './reducers/loading';
import termsReducer from './reducers/terms';
import consentsReducer from './reducers/consents';
import pinCodesReducer from './reducers/pinCodes';
import modelsReducer from './reducers/models';
import profileNotifiableReducer from './reducers/providersNotifiable';
import firmwaresNotifiableReducer from './reducers/firmwaresNotifiable';
import errorsReducer from './reducers/errors';
import locationsReducer from './reducers/locations';
import PatientDataReducer from './reducers/patientData';
import dashboardReducer from './reducers/dashboard';
import engineeringReducer from './reducers/engineering';
import patientProfilesReducer from './reducers/patients';
import unresolvedErrorsReducer from './reducers/unresolvedErrors';
import patientsInfoReducer from './reducers/patientsInfo';
import engineeringParameterReducer from './reducers/engineeringParams';
import engineeringValueReducer from './reducers/engineeringVals';
import resolveDashboardErrorsReducer from './reducers/resolveDashboardErrors';
import translationReducer from './reducers/translations';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
	loading: loadingReducer,
	auth: authReducer,
	user: userReducer,
	errors: errorsReducer,
	models: modelsReducer,
	providersNotifiableData: profileNotifiableReducer,
	firmwaresNotifiableData: firmwaresNotifiableReducer,
	pinCodes: pinCodesReducer,
	roles: rolesReducer,
	providers: providersReducer,
	dashboard: dashboardReducer,
	engineering: engineeringReducer,
	concentrators: concentratorsReducer,
	reports: reportsReducer,
	pushNotifications: pushNotificationsReducer,
	manageUsers: manageUsersReducer,
	firmware: firmwareReducer,
	terms: termsReducer,
	consents: consentsReducer,
	locations: locationsReducer,
	patientData: PatientDataReducer,
	patientSearchData: patientProfilesReducer,
	unresolvedErrors: unresolvedErrorsReducer,
	patientsInfo: patientsInfoReducer,
	engineeringParameters: engineeringParameterReducer,
	engineeringValue: engineeringValueReducer,
	resolveDashboardErrors: resolveDashboardErrorsReducer,
	translations: translationReducer,
});

const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk)),
);

export default store;
