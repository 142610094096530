import React, { Fragment } from 'react';
import { permissions } from 'helpers';
import { Button, DropdownList, ValidateError } from 'components';
import CheckboxList from '../CheckboxList';
import CheckboxListFirmware from '../CheckboxListFirmware';
import FiltersList from './FiltersList';
import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const SecondStep = (props) => {
	const {
		controlsGroups: {
			pinCodes,
			errors,
			models,
			dataValues,
			FirmwareData_LV,
			FirmwareData_OV,
		},
		controlsGroups,
		errorsMsg,
		isAllDevices,
		isSending,
		matches,

		pinCodesData,
		errorsData,
		modelsData,
		providersNotifiabledata,
		firmwaresNotifiabledata,
		checkboxChangedHandler,
		onItemRemove,
		checkAllHandler,
		t,
	} = props;

	var firmware_data_notifiable = [];
	var counter = 0;
	firmwaresNotifiabledata &&
		firmwaresNotifiabledata.map((data) => {
			var json_data = { name: '', latest_version: [], old_version: [] };
			if (data && data.name) {
				json_data['name'] = data.name;
			}
			if (data && data.latest_version && data.old_version) {
				if (data.latest_version && data.latest_version.length > 0) {
					data.latest_version.map((firmware_data) => {
						if (firmware_data.concentrator_firmware_id) {
							json_data['latest_version'].push(
								firmware_data.concentrator_firmware_id,
							);
						}
						return '';
					});
				} else {
					json_data['latest_version'].push(++counter + 'latest_version');
				}
			}
			if (data && data.latest_version && data.old_version) {
				if (data.old_version && data.old_version.length > 0) {
					data.old_version.map((firmware_data) => {
						if (firmware_data.concentrator_firmware_id) {
							json_data['old_version'].push(
								firmware_data.concentrator_firmware_id,
							);
						}
						return '';
					});
				} else {
					json_data['old_version'].push(++counter + 'old_version');
				}
			}
			firmware_data_notifiable.push(json_data);
			return '';
		});

	const data = [
		{
			id: 2,
			label: i18next.t('errors'),
			content: (
				<CheckboxList
					data={errorsData}
					labelKey='description'
					onChange={(event, el) => checkboxChangedHandler(event, el, 'errors')}
					config={{
						elementType: 'input',
						elementConfig: {
							type: 'checkbox',
						},
						theme: 'checkbox',
						className: s.checkbox,
						value: errors,
						checkAll: isAllDevices,
					}}
				/>
			),
		},
		{
			id: 3,
			label: i18next.t('hardware+version'),
			content: (
				<CheckboxList
					data={modelsData}
					labelKey='description'
					onChange={(event, el) => checkboxChangedHandler(event, el, 'models')}
					config={{
						elementType: 'input',
						elementConfig: {
							type: 'checkbox',
						},
						theme: 'checkbox',
						className: s.checkbox,
						value: models,
						checkAll: isAllDevices,
					}}
				/>
			),
		},
		{
			id: 4,
			label: i18next.t('software_version'),
			content: (
				<CheckboxListFirmware
					data={firmware_data_notifiable}
					labelKey='latest version'
					onChange={(event, el, Type) =>
						checkboxChangedHandler(event, el, Type)
					}
					className={s.timeZonefirmware}
					config={{
						elementType: 'input',
						elementConfig: {
							type: 'checkbox',
						},
						theme: 'checkbox',
						className: s.checkboxFirmware,
						value1: FirmwareData_LV,
						value2: FirmwareData_OV,
						checkAll: isAllDevices,
					}}
				/>
			),
		},
	];

	if (permissions.check('notifications.provider.filter')) {
		data.unshift({
			id: 0,
			label: 'providers',
			content: (
				<Fragment>
					<CheckboxList
						data={providersNotifiabledata}
						labelKey='name'
						key={0}
						onChange={(event, el) =>
							checkboxChangedHandler(event, el, 'dataValues')
						}
						className={s.checkbox}
						config={{
							elementType: 'input',
							elementConfig: {
								type: 'checkbox',
							},
							theme: 'checkbox',
							className: s.checkbox,
							value: dataValues,
							checkAll: isAllDevices,
						}}
					/>
				</Fragment>
			),
		});
	} else {
		data.unshift({
			id: 1,
			label: 'PIN codes',
			content: (
				<CheckboxList
					data={pinCodesData}
					labelKey='code'
					key={1}
					onChange={(event, el) =>
						checkboxChangedHandler(event, el, 'pinCodes')
					}
					config={{
						elementType: 'input',
						elementConfig: {
							type: 'checkbox',
						},
						theme: 'checkbox',
						className: s.checkbox,
						value: pinCodes,
						checkAll: isAllDevices,
					}}
				/>
			),
		});
	}

	let matchesText;

	if (Number.isInteger(matches)) {
		if (matches) {
			matchesText = (
				<div className={s.matches_new}>
					{matches} {t('concentrators_match_this_filter')}
				</div>
			);
		} else {
			matchesText = (
				<div className={s.noMatches}>
					0 {t('concentrators_match_this_filter')}
				</div>
			);
		}
	}

	return (
		<Fragment>
			{/* <Input
                label={t('all_devices')}
                theme="checkbox"
                className={s.checkbox}
                elementType="input"
                elementConfig={{ type: "checkbox" }}
                checked={isAllDevices}
                changed={checkAllHandler} /> */}
			<div className={s.SecondStepStyle}>
				<div className={s.allDevicesStyle}>
					<input
						type='checkbox'
						checked={isAllDevices}
						onClick={checkAllHandler}
						onChange={() => {}} // to avoid warning
					/>
					<span className={s.spanStyle}>{t('all_devices')}</span>
				</div>
				<DropdownList data={data} newDesign={true} className={s.dropdownList} />
			</div>
			<FiltersList
				controlsGroups={controlsGroups}
				allChecked={isAllDevices}
				checkboxChangedHandler={checkboxChangedHandler}
				checkAllHandler={checkAllHandler}
				onItemRemove={onItemRemove}
			/>

			{matchesText}
			<div>
				{' '}
				<span className={s.notefirmware}>{i18next.t('note')}: </span>
				<span className={s.notificationNoteDescription}>
					{i18next.t(
						'notification_will_be_sent_at_this_time_in_every_time_zone',
					)}
				</span>
			</div>
			<div className={[s.button, s.secondStepButton].join(' ')}>
				<Button
					btnType='type-21'
					disabled={isSending || matches === 0 || matches === null}
					clicked={props.sendHandler}
				>
					{i18next.t('send')}
				</Button>
			</div>

			{errorsMsg && <ValidateError data={errorsMsg} className={s.errors} />}
		</Fragment>
	);
};

export default withTranslation()(SecondStep);
