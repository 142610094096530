import React from 'react';
import T from 'prop-types';
import i18next from 'i18next';

const NoDataFound = (props) => {
    // const { filters } = props;

    return (
        <div className={props.className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 400 300"
                width="400"
                height="300"
            >
                {/* Background */}
                <rect width="400" height="300" fill="#f9f9f9" />

                {/* Cloud */}
                <ellipse cx="200" cy="120" rx="80" ry="50" fill="#e0e0e0" />
                <ellipse cx="160" cy="130" rx="60" ry="40" fill="#e0e0e0" />
                <ellipse cx="240" cy="130" rx="60" ry="40" fill="#e0e0e0" />

                {/* Folder Icon */}
                <rect x="120" y="150" width="160" height="100" rx="10" fill="#ffcc80" />
                <rect x="120" y="140" width="80" height="20" rx="5" fill="#ffd699" />
                <rect x="200" y="140" width="80" height="20" rx="5" fill="#ffb74d" />

                {/* Sad Face */}
                <circle cx="200" cy="200" r="20" fill="#fff" />
                <circle cx="190" cy="195" r="2" fill="#000" />
                <circle cx="210" cy="195" r="2" fill="#000" />
                <path d="M190 205 Q200 215 210 205" stroke="#000" strokeWidth="2" fill="none" />
            </svg>

            {/* Text */}
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <p style={{ fontSize: '16px', color: '#888' }}>{i18next.t('no_data_found')}</p>
                {/* {filters && (
                    <div style={{ fontSize: '14px', color: '#555' }}>
                        <p>Filters Applied:</p>
                        <ul>
                            {Object.entries(filters).map(([key, value]) => (
                                <li key={key}>
                                    <strong>{key}:</strong> {value || 'None'}
                                </li>
                            ))}
                        </ul>
                    </div>
                )} */}
            </div>
        </div>
    );
};

NoDataFound.defaultProps = {
    fill: 'none',
    width: 400,
    height: 300,
    viewBox: '0 0 400 300',
    xmlns: 'http://www.w3.org/2000/svg',
    filters: null,
};

NoDataFound.propTypes = {
    className: T.string,
    filters: T.object, // Filters should be an object
};

export default NoDataFound;
