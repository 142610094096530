import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './style.module.scss';
import PatientErrors from './PatientErrors';
import PatientStatus from './PatientStatus';
import i18next from 'i18next';

const PatientDashboard = ({ status }) => {
	const [activeTab, setActiveTab] = useState('errors');

	return (
		<div className={s.patientDashboard} data-testid='patient-dashboard'>
			<div className={s.tabs}>
				<button
					className={activeTab === 'errors' ? s.active : ''}
					onClick={() => setActiveTab('errors')}
					style={{ borderRadius: '4px 0 0 0' }}
					data-testid='errors-tab'
				>
					{i18next.t('my_dashboard')}
				</button>
				<button
					className={activeTab === 'status' ? s.active : ''}
					onClick={() => setActiveTab('status')}
					style={{ borderRadius: '0 4px 0 0' }}
					data-testid='status-tab'
				>
					{i18next.t('patient_device_status')}
				</button>
			</div>
			{activeTab === 'errors' && <PatientErrors data-testid='patient-errors' />}
			{activeTab === 'status' && (
				<PatientStatus status={status} data-testid='patient-status' />
			)}
		</div>
	);
};

PatientDashboard.propTypes = {
	status: PropTypes.string.isRequired,
};

export default PatientDashboard;
