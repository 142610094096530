import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	PatientsInfo: { total_rows: '', data: [] },
	loading: false,
};

export const getPatientsInfoStart = (state) => {
	return updateObject(state, { loading: true });
};

export const getPatientsInfoSuccess = (state, action) => {
	return updateObject(state, {
		PatientsInfo: {
			...state.PatientsInfo,
			total_rows: action.data.total_rows, // Store total_rows
			data: action.data.data, // Store data
		},
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PATIENT_INFO_SUCCESS:
			return getPatientsInfoSuccess(state, action);
		case actionTypes.GET_PATIENT_INFO_START:
			return getPatientsInfoStart(state);
		default:
			return state;
	}
};

export default reducer;
