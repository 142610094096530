import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import * as actions from 'store/actions';
import { ListViewDashboard } from '../../../../components';

const options = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
};

function PatientListView(props) {
	const defaultColumnProps = [
		{
			Header: '',
			accessor: 'id',
			Cell: ({ value }) => (
				<input
					type='checkbox'
					checked={props?.selectedRows.includes(value)}
					onClick={(e) => handleRowSelection(e, value)}
					onChange={() => { }} // to remove warning
				/>
			),
			hideTitle: true,
		},
		{
			Header: i18next.t('error_type'),
			accessor: 'error_description',
			sort: true,
		},
		{ Header: i18next.t('first_name'), accessor: 'first_name', sort: true },
		{ Header: i18next.t('last_name'), accessor: 'last_name', sort: true },
		{
			Header: i18next.t('time_occurred'),
			accessor: 'happened_at',
			Cell: ({ value }) => {
				const date = new Date(value);
				const year = date.getFullYear();
				let month = date.toLocaleString(i18next.language, { month: 'short' });
				month = month.replace('.', '');
				const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
				const day = date.getDate().toString().padStart(2, '0');
				const time = date.toLocaleTimeString('en-US', { options });
				return `${capitalizedMonth} ${day} ${year}, ${time}`;
			},
			sort: true,
		},
	];

	const columnProps = props.columnProps || defaultColumnProps;

	const handleRowSelection = (e, rowId) => {
		const { selectedRows } = props;
		e.stopPropagation();
		const index = selectedRows.indexOf(rowId);
		const newSelectedRows = [...selectedRows];
		if (index === -1) {
			newSelectedRows.push(rowId);
		} else {
			newSelectedRows.splice(index, 1);
		}
		props.updateSelectedRows(newSelectedRows);
	};

	const handleSortChange = (sortColumn, sortDirection) => {
		props.handleSortTypeChange(sortColumn, sortDirection);
	};

	return (
		<>
			<ListViewDashboard
				data={props?.data}
				columnSettings={columnProps}
				pageSize={props?.pageSize}
				defaultSortColumn={'happened_at'}
				defaultSortColumnDirection={'desc'}
				onSortChange={handleSortChange}
				loading={props?.loading}
				setPatientId={props.setPatientId}
			/>
		</>
	);
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPatientId: (data) => dispatch(actions.setPatientId(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientListView);
