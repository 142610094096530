import React, { useState } from 'react';
import s from './style.module.scss';
import { Spinner } from 'components';
import { toast } from 'helpers';
import { coreInstance as axios, api } from 'settings';

const RefreshButton = () => {
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);

	const handleButtonClick = async () => {
		setLoading(true);
		setProgress(0);
		const incrementValue = 100 / 210; // 96 seconds API will take to complete

		try {
			const interval = setInterval(() => {
				setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + incrementValue));
			}, 1000);

			await axios.post(`${api.demo.index}`);

			clearInterval(interval);
			setProgress(100);
		} catch (error) {
			console.error('Error:', error);
			setProgress(0);
			setLoading(false);
			toast.error('Error updating demo data');
		} finally {
			setLoading(false);
			toast.success('Demo data updated successfully');
		}
	};

	const ProgressBar = ({ progress }) => (
		<div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '5px' }}>
			<div
				style={{
					width: `${progress}%`,
					backgroundColor: '#007bff',
					height: '20px',
					borderRadius: '5px',
					transition: 'width 0.5s'
				}}
			/>
		</div>
	);

	return (
		<div>
			{loading ? <ProgressBar progress={progress} /> : null}
			<button
				onClick={handleButtonClick}
				className={`${s.refreshButton} ${loading ? s.loading : ''}`}
				disabled={loading}
			>
				{loading ? (
					<div className={s.loadMoreSpinner}>
						<Spinner />
					</div>
				) : (
					'Update Demo Data'
				)}
			</button>
			{loading && (
				<div className={s.syncMessage}>
					<p>Data Sync in Progress</p>
					<p>
						The data synchronization process has started. This may take up to 3-4 minutes to complete. During this time, please do not refresh or navigate away from this page. You can continue working on other pages and return here after 4 minutes to see the updated data.
					</p>
					<p>Thank you for your patience.</p>
				</div>
			)}
		</div>
	);
};

export default RefreshButton;
