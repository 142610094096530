import React, { Component } from 'react';
import moment from 'moment';
// import { Link } from "react-router-dom";
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ConcentratorsMap, TableFilter } from 'components';
import { logger, permissions } from 'helpers';
// import { routers } from "settings";
import { makeGetAllDashboardState } from 'store/selectors';
import * as actions from 'store/actions';
import { getParam, models, modelsParams } from './Configs/creator';
import ItemModal from './ItemModal';
import Connected from './Connected';
import RecentError from './RecentError';
import CurrentlyConnected from './CurrentlyConnected';
import ColumnLife from './ColumnLife';
import SoftwareVersion from './SoftwareVersion';
import UserDevice from './UserDevice';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filters: {},
			modal: {
				isOpen: false,
			},
		};
		const { is_inogen } = this.props.currentUserData?.provider;
		const defaultParams = {
			search: {
				provider_id: this.props?.currentUserData?.provider_id,
			},
			searchFields: {
				provider_id: 'in',
			},
		};

		props.getModels({ params: modelsParams });
		if (is_inogen) {
			props.getRecentError();
			props.getLocations({ lastMin: 10 });
			props.getLocationsCount({ lastMin: 10 });
			props.getConnected();
			props.getUserDevice();
			props.getSoftwareVersions();
			props.getColumnLife();
			props.getConnectedByTime();
		} else {
			props.getRecentError({ params: defaultParams });
			props.getLocations({ params: defaultParams, lastMin: 10 });
			props.getLocationsCount({ params: defaultParams, lastMin: 10 });
			props.getConnected({ params: defaultParams });
			props.getUserDevice({ params: defaultParams });
			props.getSoftwareVersions({ params: defaultParams });
			props.getColumnLife({ params: defaultParams });
			props.getConnectedByTime({ params: defaultParams });
		}
		// if (permissions.check('providers.main.filter')) {
		// 	// props.getProviders();
		// }
		this.inogenProvidersFlag = true;
		this.values = [];
		this.InogenFlag = false;
		this.NonInogenflag = false;
	}

	componentDidUpdate(prevProps) {
		logger('[Dashboard] componentDidUpdate');

		const {
			params,
			getRecentError,
			getLocations,
			getLocationsCount,
			getConnected,
			getUserDevice,
			getSoftwareVersions,
			getColumnLife,
			getConnectedByTime,
		} = this.props;
		const isParamsChange = params !== prevProps.params;

		if (isParamsChange) {
			getRecentError({ params });
			getLocations({ params, lastMin: 10 });
			getLocationsCount({ params, lastMin: 10 });
			getConnected({ params });
			getUserDevice({ params });
			getSoftwareVersions({ params });
			getColumnLife({ params });
			getConnectedByTime({ params, days: 30 });
		}
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.locationData;
	}

	filterAddHandler = (item, key, inogenProviders) => {
		this.inogenProvidersFlag = inogenProviders;
		ReactGA.event({
			category: 'Dashboard',
			action: 'Click',
			label: `Select ${key} filter`,
		});
		this.setState(
			(ps) => {
				const filters = { ...ps.filters };

				if (item) {
					filters[key]
						? filters[key][item.id]
							? delete filters[key][item.id]
							: (filters[key][item.id] = item)
						: (filters[key] = { [item.id]: item });
				} else {
					filters['pinCodes'] = {};
					filters['providers'] = {};
				}
				const filtererdArray =
					filters.providers && Object.values(filters.providers);
				this.values =
					filtererdArray &&
					filtererdArray.length > 0 &&
					filtererdArray.map((obj) => obj['name']);

				return { filters };
			},
			() => {
				const {
					// providers = {},
					pinCodes = {},
					models = {},
				} = this.state.filters;
				const { setDashParam, currentUserData } = this.props;
				// const providers = Object.keys(providers).length ? Object.values(providers) : null;
				const pinCodeIds = Object.keys(pinCodes).length
					? Object.keys(pinCodes)
					: null;
				const modelIds = Object.keys(models).length
					? Object.keys(models)
					: null;

				setDashParam(
					getParam({
						provider_id: currentUserData?.provider?.is_inogen
							? null
							: this.props.currentUserData?.provider_id,
						pinCodeIds,
						modelIds,
					}),
				);
			},
		);
	};

	filterClearHandler = (key) => () =>
		this.setState((prevState) => ({
			filters: {
				...prevState.filters,
				[key]: {},
			},
		}));

	pinHandler = (row) => {
		if (permissions.check('concentrators.details')) {
			const modal = { isOpen: true };

			ReactGA.event({
				category: 'Concentrators',
				action: 'Click',
				label: 'Amount of Clicks on Map Pins',
			});

			ReactGA.event({
				category: 'Concentrators',
				action: 'Click',
				label: 'Table row',
			});

			if (row.itemId && Array.isArray(row.itemId)) {
				if (row.itemId.length > 1) {
					modal.groups = row.itemId;
				} else {
					modal.rowId = row.itemId[0];
				}
			}

			if (row.model && Array.isArray(row.model)) {
				if (row.model.length > 1) {
					modal.groupsModels = row.model;
				} else {
					modal.concentrator_model_id = row.model[0];
				}
			} else {
				modal.rowId = row.id;
				modal.concentrator_model_id = row.concentrator_model_id;
			}

			this.setState({ modal });
		}
	};

	modalClose = () => {
		this.setState({ modal: { isOpen: false } });
	};

	render() {
		const {
			loadingOn,
			loadingOff,
			locationData,
			headingsData,
			modelsData,
			setDashParam,
			recentErrorData,
			recentErrorPeriod,
			connectedByHour,
			currentlyConnected,
			monthlyConnected,
			totalConnected,
			userDeviceData,
			userDeviceNames,
			softwareVersionsData,
			columnLifeData,
			appConnected,
			t,
			currentUserData,
		} = this.props;
		const {
			filters,
			modal: { rowId, isOpen, groups },
		} = this.state;

		const connectedByHourData = connectedByHour.data.map((el) => {
			el.time = moment(el.connected_hour_at).local().format('H:mm');
			return el;
		});

		const defaultClickHandler = (config) =>
			setDashParam({
				search: {
					[config.param]: null,
				},
				searchFields: {
					[config.param]: null,
				},
			});
		const itemClickHandler = (item) => {
			this.filterAddHandler(item, 'models');
		};
		this.InogenFlag = false;
		this.NonInogenflag = false;
		this.InogenProviders = true;
		this.values &&
			this.values.length > 0 &&
			this.values.map((data) => {
				if (
					data &&
					(data === t('inogen') || data === t('inogen_engineering'))
				) {
					this.InogenFlag = true;
				} else {
					this.NonInogenflag = true;
				}
				return data;
			});
		this.InogenFlag && this.NonInogenflag
			? (this.InogenProviders = false)
			: this.InogenFlag
			  ? (this.InogenProviders = true)
			  : this.NonInogenflag
			    ? (this.InogenProviders = false)
			    : (this.InogenProviders = true);
		currentUserData &&
			currentUserData.roles &&
			currentUserData.roles.length > 0 &&
			currentUserData.roles.map((userData) => {
				return userData;
			});

		return (
			<div className={s.page}>
				<div className={s.content}>
					<h1 className={s.pageTitle}>
						{t('dashboard')}
						<div className={s.filters}>
							<TableFilter
								className={s.modelFilter}
								checked={filters.models || {}}
								config={models}
								data={modelsData}
								onSetParams={setDashParam}
								onItemClick={itemClickHandler}
								defaultChanged={defaultClickHandler}
								clear={this.filterClearHandler('models')}
							/>
						</div>
					</h1>

					<div className={s.board}>
						<div className={s.full}>
							<div className={s.panel}>
								{/* <div className={s.mapHead}>
									<h2 className={s.title}>
										{countData} {t('concentrators')}
										<Info
											fill='#a5bad4'
											data-tip=''
											data-for='Concentrators'
											className={s.infoIconTrigger}
										/>
										<span>{t('last_10_min')}</span>
									</h2>
									<ReactTooltip
										id='Concentrators'
										className={s.tooltip}
										place='bottom'
										type='light'
										effect='solid'
									>
										<Info fill='#a5bad4' className={s.infoIconTooltip} />
										<p>{t('total_number_of_concentrators_sent_10_minutes')}</p>
									</ReactTooltip>
									<Link
										to={routers.concentrators + "?search"}
										className={s.link}
									>
										<Search fill="#30adfa" className={s.icon} />{" "}
										<span>{t("search_concentrator_on_map")}</span>
									</Link>
								</div> */}

								{this.props.scriptLoaded === true ? (
									<ConcentratorsMap
										data={locationData}
										headings={headingsData}
										// onClick={this.pinHandler}
									/>
								) : null}
							</div>
						</div>

						<RecentError
							data={recentErrorData}
							period={recentErrorPeriod}
							total={appConnected}
						/>

						<CurrentlyConnected
							currentlyConnected={currentlyConnected}
							total={totalConnected}
							monthlyConnected={monthlyConnected}
						/>

						<ColumnLife data={columnLifeData} />

						<Connected
							data={connectedByHourData}
							total={totalConnected}
							period={connectedByHour.period}
						/>

						<SoftwareVersion
							data={softwareVersionsData}
							modelsData={modelsData}
						/>

						<UserDevice data={userDeviceData} names={userDeviceNames} />
					</div>
				</div>

				<ItemModal
					itemId={rowId}
					isOpen={isOpen}
					groups={groups}
					modelId={this.state.modal.concentrator_model_id}
					modelGroups={this.state.modal.groupsModels}
					onRowClicked={this.pinHandler}
					closeModal={this.modalClose}
					loadingOn={loadingOn}
					loadingOff={loadingOff}
				/>
			</div>
		);
	}
}

const mapStateToProps = () => {
	const getAllDashboardState = makeGetAllDashboardState();
	return (props, state) => getAllDashboardState(props, state);
};

const mapDispatchToProps = (dispatch) => ({
	setDashParam: (params) => dispatch(actions.setDashParam(params)),
	getLocations: (args) => dispatch(actions.getLocations(args)),
	getLocationsCount: (args) => dispatch(actions.getLocationsCount(args)),
	getProviders: (args) => dispatch(actions.getProviders(args)),
	getModels: (args) => dispatch(actions.getModels(args)),
	getRecentError: (args) => dispatch(actions.getRecentError(args)),
	getConnected: (args) => dispatch(actions.getConnected(args)),
	getUserDevice: (args) => dispatch(actions.getUserDevice(args)),
	getSoftwareVersions: (args) => dispatch(actions.getSoftwareVersions(args)),
	getCurrentUser: () => dispatch(actions.getCurrentUser()),
	getColumnLife: (args) => dispatch(actions.getColumnLife(args)),
	getConnectedByTime: (args) => dispatch(actions.getConnectedByTime(args)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(Dashboard));
