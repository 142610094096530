import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import * as actions from 'store/actions';
import { InfoNew, CloseIcon } from '../../../components/Icons';
import {
	Pagination,
	SelectComponent,
	ListViewDashboard,
} from '../../../components';
import s from './style.module.scss';
import { pageOptions } from '../Config/constants';
import CreateModal from '../../PushNotifications/CreateModal';

const ConcentratorModels = {
	11: 'Inogen One G5',
	13: 'Inogen Rove 6',
	12: 'Inogen Rove 4',
	10: 'Inogen One G4',
	14: 'OxyGo FIT',
	15: 'OxyGo NEXT',
	UNKNOWN: 'Unknown Model',
	null: 'Unknown Model',
};
const options = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
};
const DEFAULT_LAST_LOGIN_DAYS = 7;

function PatientStatus(props) {
	const [selectedModelType, setSelectedModelType] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [selectedRows, setSelectedRows] = useState([]);
	const [filterModelOptions, setFilterModelOptions] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortingOn, setSortingOn] = useState('connected_at');
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [offSet, setOffSet] = useState(0);
	const [selectLastLoginType, setSelectLastLoginType] = useState(
		DEFAULT_LAST_LOGIN_DAYS,
	);
	const [searchValue, setSearchValue] = useState('');
	const inputSearchRef = React.createRef();
	const [createModal, setCreateModal] = useState({
		isOpen: false,
		restrictClosing: false,
	});
	const [queryParams, setQueryParams] = useState({
		last_login_days: selectLastLoginType || DEFAULT_LAST_LOGIN_DAYS, // Use the selected value or default to 30
		limit: pageSize,
		orderBy: sortingOn,
		sortedBy: sortingDirection,
		offset: offSet,
	});

	// lastlogin state. It will be static list with (last 30days, last 15days, last 7days)
	const lastLoginTypeOptions = [
		{ key: '30_days', value: 30, label: i18next.t('older_than_30days') },
		{ key: '15_days', value: 15, label: i18next.t('older_than_15days') },
		{ key: '7_days', value: 7, label: i18next.t('older_than_7days') },
	];
	const columnProps = [
		{
			Header: (
				<input
					type='checkbox'
					checked={selectedRows.length === props?.patientsInfo?.total_rows}
					onChange={(e) => handleSelectAll(e.target.checked)}
				/>
			),
			accessor: 'serial_number',
			Cell: ({ value }) => (
				<input
					type='checkbox'
					checked={selectedRows?.includes(value) || false}
					onClick={(e) => handleRowSelection(e, value)}
					onChange={() => {}} // Prevent React warning
				/>
			),
			hideTitle: true,
		},
		{
			Header: i18next.t('serial_number'),
			accessor: 'serial_number',
			sort: false,
		},
		{
			Header: i18next.t('first_name'),
			accessor: 'first_name',
			sort: false,
		},
		{
			Header: i18next.t('last_name'),
			accessor: 'last_name',
			sort: false,
		},
		{
			Header: i18next.t('last_login_date'),
			accessor: 'connected_at',
			Cell: ({ value }) => {
				const date = new Date(value);
				const time = date.toLocaleTimeString('en-US', options); // Pass options directly
				const result = `${time}`;
				return result;
			},
			sort: true,
		},
		{
			Header: i18next.t('last_notification_sent'),
			accessor: 'last_notification_at',
			Cell: ({ value }) => {
				const date = new Date(value);
				const time = date.toLocaleTimeString('en-US', options); // Pass options directly
				const result = `${time}`;
				console.log('result', result);
				return result;
			},
			sort: true,
		},
		{
			Header: i18next.t('last_location'),
			accessor: 'last_location',
			sort: false,
		},
		{
			Header: i18next.t('last_error'),
			accessor: 'last_error',
			sort: false,
		},
		{
			Header: i18next.t('model_type'),
			accessor: 'description',
			sort: false,
		},
		{
			Header: i18next.t('battery_charge_capacity'),
			accessor: 'battery_capacity',
			Cell: ({ value }) => (value !== null ? `${value}%` : i18next.t('0%')),
			sort: false,
		},
		{
			Header: i18next.t('sieve_life'),
			accessor: 'sieve_life',
			Cell: ({ value }) =>
				value !== null ? `${value} hours` : i18next.t('0 hours'),
			sort: false,
		},
		{
			Header: i18next.t('oxygen_purity'),
			accessor: 'oxygen_purity',
			Cell: ({ value }) => (value !== null ? `${value}%` : i18next.t('0%')),
			sort: false,
		},
	];

	const handleSelectAll = async (isChecked) => {
		if (isChecked) {
			// Fetch all rows
			const params = {
				...queryParams, // Keep existing filters
				limit: props?.patientsInfo?.total_rows, // Fetch all rows
				offset: 0,
			};

			try {
				setQueryParams(params);
				const response = await props.getPatientsInfo({ params });
				const allRowAccessors = response.data.map((row) => row.serial_number);
				setSelectedRows(allRowAccessors);
			} catch (error) {
				console.error('Error fetching all rows:', error);
			}
		} else {
			// Deselect all rows
			setSelectedRows([]);
		}
	};
	const onCreateNotification = () => {
		props.getPushNotificationsTypes();
		setCreateModal({ isOpen: true, restrictClosing: true });
	};
	const modalClose = () => {
		setCreateModal({ isOpen: false, restrictClosing: false });
	};
	const handleRowSelection = (e, rowId) => {
		e.stopPropagation();
		const index = selectedRows.indexOf(rowId);
		const newSelectedRows = [...selectedRows];
		if (index === -1) {
			newSelectedRows.push(rowId);
		} else {
			newSelectedRows.splice(index, 1);
		}
		updateSelectedRows(newSelectedRows);
	};
	const filterModelTypeOPtions = (modelOptions) => {
		const modelUpdatedOption = modelOptions.map((currentItem) => {
			return {
				value: currentItem.id,
				label: currentItem.description,
			};
		});
		setFilterModelOptions(modelUpdatedOption);
	};
	const handleLastLoginTypeChange = (value) => {
		try {
			setSelectLastLoginType(value); // Update the selected value
			setSelectedRows([]); // Clear selected rows
		} catch (e) {
			console.log(e.message);
		}
	};
	const handleModelTypeChange = (value) => {
		try {
			setSelectedModelType(value);
			setSelectedRows([]);
		} catch (e) {
			console.log(e.message);
		}
	};
	const handlePageSizeChange = (value) => {
		try {
			setSelectedRows([]);
			setOffSet(0);
			setPageSize(value);
			setCurrentPage(1);
		} catch (e) {
			console.log(e.message);
		}
	};
	const updateSelectedRows = (errorIds) => {
		setSelectedRows(errorIds);
	};
	const handleSortTypeChange = (sortOn, sortDirection) => {
		setSelectedRows([]);
		setSortingOn(sortOn);
		setSortingDirection(sortDirection);
	};
	const handlePageNumberChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		setOffSet(pageNumber === 1 ? 0 : (pageNumber - 1) * pageSize);
	};
	let patientInfoData = props?.patientsInfo?.data?.map((item) => {
		const healthCheckParams = item.all_healthcheck_parameters || [];
		const batteryCapacity =
			healthCheckParams.find((param) => param.healthcheck_parameter_id === 1)
				?.value ?? null;
		const sieveLife =
			healthCheckParams.find((param) => param.healthcheck_parameter_id === 5)
				?.value ?? null;
		const oxygenPurity =
			healthCheckParams.find((param) => param.healthcheck_parameter_id === 7)
				?.value ?? null;

		// Get the description based on concentrator_model_id
		const description =
			ConcentratorModels[item.concentrator_model_id] ||
			ConcentratorModels.UNKNOWN;

		return {
			...item,
			battery_capacity: batteryCapacity,
			sieve_life: sieveLife,
			oxygen_purity: oxygenPurity,
			description, // Add the description column
		};
	});
	const onSearchSubmit = (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		if (inputSearchRef.current) {
			inputSearchRef.current.focus();
		}
		const value = inputSearchRef.current.value;
		const params = {
			search: `name:${value}`,
			searchFields: `name:ilike`,
			last_login_days: selectLastLoginType || DEFAULT_LAST_LOGIN_DAYS, // Use the selected value or default to 30
		};

		// Update the queryParams state with the new search value
		setQueryParams(params);
		props.getPatientsInfo({ params: params });
	};
	const onSearchClear = () => {
		setSearchValue('');
		const params = {
			last_login_days: selectLastLoginType || DEFAULT_LAST_LOGIN_DAYS, // Use the selected value or default to 30
		};
		setQueryParams(params);
		props.getPatientsInfo({ params: params });
	};
	const handleInputChange = (event) => {
		setSearchValue(event.target.value);
	};
	const handleResetFilterClick = () => {
		setSelectedModelType([]);
		setSelectLastLoginType(DEFAULT_LAST_LOGIN_DAYS); // Reset to default value
		setSelectedRows([]);
		setSearchValue('');
		setOffSet(0);
		setPageSize(10);
		setCurrentPage(1);
	};

	// fltering model type options
	useEffect(() => {
		filterModelTypeOPtions(props?.modelTypeOptions);
	}, [props.modelTypeOptions]);

	// fetching unresolved errors based on selected filters,pageSize, sorting and pagination and updating the table
	useEffect(() => {
		const params = {
			last_login_days: selectLastLoginType || DEFAULT_LAST_LOGIN_DAYS, // Use the selected value or default to 30
			limit: pageSize,
			orderBy: sortingOn,
			sortedBy: sortingDirection,
			offset: offSet,
		};
		// Add search and searchFields if selectedModelType has values
		if (selectedModelType && selectedModelType.length > 0) {
			const modelIds = selectedModelType.join(',');
			params.search = {
				concentrator_model_id: modelIds,
			};
			params.searchFields = {
				concentrator_model_id: 'in',
			};
		}
		setQueryParams(params);
		props.getPatientsInfo({ params: params });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		sortingOn,
		sortingDirection,
		selectedModelType,
		pageSize,
		offSet,
		selectLastLoginType,
	]);

	return (
		<div className={s.patientWrapper}>
			<div className={s.patientContainer}>
				<div>
					<div className={s.patientSearchstyle}>
						<form onSubmit={onSearchSubmit}>
							<div className={s.inputwrapper}>
								<input
									className={s.searchinput}
									ref={inputSearchRef}
									type='text'
									onChange={handleInputChange}
									value={searchValue}
									placeholder={i18next.t('search_by_first_name_last_name')}
								/>
								{searchValue && (
									<div className={s.spanStyle} onClick={onSearchClear}>
										<CloseIcon />
									</div>
								)}
								<button
									className={s.btnstyle}
									id='btnId'
									data-testid='search-button'
									disabled={!searchValue}
									type='submit'
								>
									{i18next.t('search')}
								</button>
							</div>
						</form>
					</div>
				</div>
				<div className={s.patientliststyle}>
					<div className={s.title_align}>
						<div className={s.filterAlign}>
							<div className={s.dashboadIssue}>
								<span>{i18next.t('Device_status')}</span>
								<InfoNew data-tip='' data-for='dashboardError' />
								<ReactTooltip
									id='dashboardError'
									className={s.tooltip}
									place='bottom'
									type='light'
									effect='solid'
								>
									<p>{i18next.t('patient_login_status_with_other_details')}</p>
								</ReactTooltip>
							</div>
							<div className={s.switch}>
								<div className={s.filter_options}>
									<SelectComponent
										key='lastLoginType'
										isMulti={false} // Ensure single selection
										options={lastLoginTypeOptions} // Use options with unique keys
										value={selectLastLoginType} // Bind to selected value
										onChange={handleLastLoginTypeChange} // Handle changes
										placeholder={i18next.t('select_last_login_users')}
										className={s.selectfilterStyling}
										singleSelectedLable={i18next.t('select_last_login_users')}
									/>

									<SelectComponent
										key='modelType'
										isMulti={true}
										options={filterModelOptions}
										value={selectedModelType}
										onChange={handleModelTypeChange}
										placeholder={i18next.t('model_type_all')}
										className={s.selectfilterStyling}
									/>
								</div>
							</div>
						</div>
						<div className={s.filterAlign + ' ' + s.filterButtons}>
							<button
								className={s.btnStyle}
								id='btnId'
								data-testid='resolveBtn'
								disabled={selectedRows.length === 0}
								onClick={() => onCreateNotification()}
							>
								{i18next.t('Send Notification')}
							</button>

							<button
								className={s.btnStyle}
								id='btnId'
								data-testid='resolveBtn'
								// disabled={selectedRows.length === 0}
								onClick={() => handleResetFilterClick()}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='25'
									viewBox='0 0 24 25'
									fill='none'
								>
									<path
										d='M11 20.9307C9.12433 20.687 7.57208 19.8608 6.34325 18.4519C5.11442 17.0429 4.5 15.3923 4.5 13.4999C4.5 12.4833 4.70067 11.5093 5.102 10.5779C5.50333 9.64644 6.0655 8.82811 6.7885 8.12294L7.85775 9.19219C7.24358 9.75253 6.78042 10.4029 6.46825 11.1432C6.15608 11.8835 6 12.6691 6 13.4999C6 14.9666 6.46825 16.2593 7.40475 17.3779C8.34125 18.4964 9.53967 19.1807 11 19.4307V20.9307ZM13 20.9499V19.4499C14.4437 19.1576 15.6379 18.4579 16.5828 17.3509C17.5276 16.2439 18 14.9603 18 13.4999C18 11.8333 17.4167 10.4166 16.25 9.24994C15.0833 8.08328 13.6667 7.49994 12 7.49994H11.6463L12.9962 8.84994L11.9423 9.90369L8.7885 6.74994L11.9423 3.59619L12.9962 4.64994L11.6463 5.99994H12C14.0923 5.99994 15.8654 6.72686 17.3193 8.18069C18.7731 9.63452 19.5 11.4076 19.5 13.4999C19.5 15.3819 18.884 17.0243 17.652 18.4269C16.4198 19.8294 14.8692 20.6704 13 20.9499Z'
										fill='white'
									/>
								</svg>
								{i18next.t('reset_filter')}
							</button>
						</div>
					</div>
					<div className={s.errorContentWrapper}>
						<div>
							<ListViewDashboard
								data={patientInfoData}
								columnSettings={columnProps}
								pageSize={pageSize}
								defaultSortColumn={'last_login'}
								defaultSortColumnDirection={'desc'}
								onSortChange={handleSortTypeChange}
								loading={props?.loading}
								selectedRows={selectedRows}
								handleSelectAll={handleSelectAll}
								frozenColumns={4}
								frozenColumnWidths={[80, 135, 135, 135]}
								setPatientId={props.setPatientId}
							/>
						</div>
					</div>
					{props?.patientsInfo?.total_rows > 0 && (
						<Pagination
							options={pageOptions}
							pageSize={pageSize}
							currentPage={currentPage}
							endIndex={pageSize}
							onPageChange={handlePageNumberChange}
							totalCount={props?.patientsInfo?.total_rows}
							handlePageSize={handlePageSizeChange}
							loading={props?.loading}
						/>
					)}

					{/* Add CreateModal */}
					{createModal.isOpen && (
						<CreateModal
							isOpen={createModal.isOpen}
							restrictClosing={createModal.restrictClosing}
							closeModal={modalClose} // Pass the close function
							typesData={props.typesData}
							currentUserData={props.currentUserData}
							addPushNotification={props.addPushNotification}
							update={() => {
								console.log('Update function triggered');
							}}
							user={props.user}
							selectedSerialNumbers={selectedRows} // Pass selected serial numbers to CreateModal
							isMultiStepForm={false} // Set to false for single step
						/>
					)}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		modelTypeOptions: state.models.data,
		loading: state.patientsInfo.loading,
		patientsInfo: state.patientsInfo.PatientsInfo, // Corrected to match the state structure
		typesData: state.pushNotifications.types.data, // Map typesData from Redux
		typesWithIconData: state.pushNotifications.typesWithIconData, // Map typesWithIconData from Redux
		user: state.user.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPatientsInfo: (args) => dispatch(actions.getPatientsInfo(args)),
		getModels: (args) => dispatch(actions.getModels(args)),
		setPatientId: (data) => dispatch(actions.setPatientId(data)),
		addPushNotification: (data) => dispatch(actions.addPushNotification(data)),
		getPushNotificationsTypes: () =>
			dispatch(actions.getPushNotificationsTypes()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(PatientStatus));
