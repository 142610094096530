import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import * as actions from 'store/actions';
import PatientSearch from '../PatientSearch/index';
import PatientListView from '../PatientUnresolvedIssues/ListView/index';
import PatientGridView from '../PatientUnresolvedIssues/GridView/index';
import { InfoNew, List, Grid } from '../../../components/Icons';
import { ToggleButton, Pagination, SelectComponent } from '../../../components';
import s from './style.module.scss';
import { Modal } from 'components';
import {
	errorsParams,
	modelsParams,
	unresolvedErrorsParams,
} from '../Config/paramsCreator';
import { pageOptions } from '../Config/constants';

function PatientErrors(props) {
	const [showGrid, setShowList] = useState(false);
	const [selectedErrorType, setSelectedErrorType] = useState([]);
	const [selectedModelType, setSelectedModelType] = useState([]);
	const [pageSize, setPageSize] = useState(20);
	const [isOldestSorting, setIsOldestSorting] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [showResolutionModal, setShowResolutionModal] = useState(false);
	const [filterErrorOptions, setFilterErrorOptions] = useState([]);
	const [filterModelOptions, setFilterModelOptions] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortingOn, setSortingOn] = useState('happened_at');
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [offSet, setOffSet] = useState(0);
	const [paramsAtResolve, setParamsAtResolve] = useState({});

	// fltering error type options based on code_string
	useEffect(() => {
		filterErrorTypeOPtions(props?.errorTypeOptions);
	}, [props.errorTypeOptions]);

	// fltering model type options
	useEffect(() => {
		filterModelTypeOPtions(props?.modelTypeOptions);
	}, [props.modelTypeOptions]);

	// fetching unresolved errors based on selected filters,pageSize, sorting and pagination and updating the table
	useEffect(() => {
		const params = {
			...unresolvedErrorsParams,
			limit: pageSize,
			orderBy: sortingOn,
			sortedBy: sortingDirection,
			offset: offSet,
		};
		let errorCodes = [];
		let errorIds = [];
		if (selectedErrorType.length > 0) {
			selectedErrorType.forEach((item) => {
				let codeString = item.split(',');
				codeString.forEach((i) => {
					let parts = i.split(':');
					if (parts.length > 1) {
						let code = parts[0];
						let id = parts[1];
						errorCodes.push(code);
						errorIds.push(id);
					}
				});
			});
		}
		var uniqueErrorIds = errorIds.filter(
			(item, index) => errorIds.indexOf(item) === index,
		);

		if (selectedErrorType.length > 0) {
			params.searchFields = {
				error_code: 'in',
			};
			params.search = {
				error_code: errorCodes.join(','),
				error_id: uniqueErrorIds.join(','),
			};
		}
		if (selectedModelType.length > 0) {
			params.searchFields = {
				concentrator_model_id: 'in',
			};
			params.search = {
				concentrator_model_id: selectedModelType.join(','),
			};
		}
		if (selectedErrorType.length > 0 && selectedModelType.length > 0) {
			params.searchFields = {
				error_code: 'in',
				concentrator_model_id: 'in',
			};
			params.search = {
				error_code: errorCodes.join(','),
				error_id: uniqueErrorIds.join(','),
				concentrator_model_id: selectedModelType.join(','),
			};
		}
		setParamsAtResolve(params);
		props.getUnresolvedErrors({ params: params });
		if (!filterErrorOptions.length && !filterModelOptions.length) {
			props.getErrors({ params: errorsParams });
			props.getModels({ params: modelsParams });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		sortingOn,
		sortingDirection,
		selectedErrorType,
		selectedModelType,
		pageSize,
		offSet,
	]);

	// handle of toggle button switch
	const handleSortingToggle = () => {
		const sortingDirection = isOldestSorting ? 'desc' : 'asc';
		setSortingDirection(sortingDirection);
		setIsOldestSorting(!isOldestSorting);
	};

	// fltering error type options based on code_string
	const filterErrorTypeOPtions = (errorOptions) => {
		const error_id_to_filter = [
			'System Error',
			'O2 Delivery Error',
			'Replace Columns',
		];
		const filteredData = errorOptions?.filter((item) =>
			error_id_to_filter.includes(item.code_string),
		);
		const combinedArray = filteredData?.reduce((eachItem, currentItem) => {
			const existingObjIndex = eachItem.findIndex(
				(obj) => obj.code_string === currentItem.code_string,
			);

			if (existingObjIndex !== -1) {
				eachItem[existingObjIndex].value += `,${
					currentItem.code + ':' + currentItem.id
				}`;
			} else {
				eachItem.push({
					code: currentItem.code,
					code_string: currentItem.code_string,
					value: currentItem.code + ':' + currentItem.id,
					concentrator_model_id: currentItem.concentrator_model_id,
					label: currentItem.description,
					description_engineer: currentItem.description_engineer,
					id: currentItem.id,
					type: currentItem.type,
				});
			}
			return eachItem;
		}, []);
		errorOptions = combinedArray;
		setFilterErrorOptions(errorOptions);
	};

	// fltering model type options
	const filterModelTypeOPtions = (modelOptions) => {
		const modelUpdatedOption = modelOptions.map((currentItem) => {
			return {
				value: currentItem.id,
				label: currentItem.description,
			};
		});
		setFilterModelOptions(modelUpdatedOption);
	};

	// handle of sorting type change from the table header in PatientListView
	const handleSortTypeChange = (sortOn, sortDirection) => {
		setSelectedRows([]);
		setSortingOn(sortOn);
		setSortingDirection(sortDirection);
	};

	// handle of error type change from the filter
	const handleErrorTypeChange = (value) => {
		try {
			setSelectedErrorType(value);
			setSelectedRows([]);
		} catch (e) {
			console.log(e.message);
		}
	};

	// handle of model type change from the filter
	const handleModelTypeChange = (value) => {
		try {
			setSelectedModelType(value);
			setSelectedRows([]);
		} catch (e) {
			console.log(e.message);
		}
	};

	// handle of page size change from the pagination
	const handlePageSizeChange = (value) => {
		try {
			setSelectedRows([]);
			setOffSet(0);
			setPageSize(value);
			setCurrentPage(1);
		} catch (e) {
			console.log(e.message);
		}
	};

	// handle of selected rows change from the table to be resolved
	const updateSelectedRows = (errorIds) => {
		setSelectedRows(errorIds);
	};

	// handle of resolve button click
	const handleResolveClick = () => {
		setShowResolutionModal(true);
	};

	// handle of modal close
	const modalCloseHandler = () => {
		setShowResolutionModal(false);
		setSelectedRows([]);
	};

	// handle of clicking on resolve error
	// making API call to mark the selected error as resolved and fetch new data with presected filters
	const handleResolveError = () => {
		setShowResolutionModal(false);
		setSelectedRows([]);
		let errorDetails = props?.unresolvedError?.data
			?.filter((item) => selectedRows.includes(item.id))
			.map((item) => {
				return {
					error_id: item.error_id,
					code: item.code,
					patient_id: item.patient_id,
				};
			});
		props.resolveDashboardErros({
			error_details: errorDetails,
			unresolvedErrorsParams: paramsAtResolve,
		});
	};

	useEffect(() => {}, [selectedRows]);

	// handle of page number change from the pagination
	const handlePageNumberChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		setOffSet(pageNumber === 1 ? 0 : (pageNumber - 1) * pageSize);
	};

	let errorData = filterErrorOptions.map((item) => {
		return {
			...item,
			label: i18next.t(item.code_string),
		};
	});
	let errorDescription = props?.unresolvedError?.data?.map((item) => {
		return {
			...item,
			error_description: i18next.t(item.error_description),
		};
	});


	console.log('errorDescription111', errorDescription);
	const sortingClass = [s.sortingTypography, s.active];
	return (
		<div className={s.patientWrapper}>
			<div className={s.patientContainer}>
				<div>
					{/* <h1 className={s.titlestyle}>{i18next.t('patient_dashboard')}</h1> */}
					<div className={s.patientSearchstyle}>
						<PatientSearch />
					</div>
				</div>
				<div className={s.patientliststyle}>
					<div className={s.title_align}>
						<div className={s.filterAlign}>
							<div className={s.dashboadIssue}>
								<span>{i18next.t('unresolved_issue')}</span>
								<InfoNew data-tip='' data-for='dashboardError' />
								<ReactTooltip
									id='dashboardError'
									className={s.tooltip}
									place='bottom'
									type='light'
									effect='solid'
								>
									<p>{i18next.t('errorDashboardMessage')}</p>
								</ReactTooltip>
							</div>
							<div className={s.switch}>
								<div className={s.filter_options}>
									<SelectComponent
										key='errorType'
										isMulti={true}
										options={errorData}
										value={selectedErrorType}
										onChange={handleErrorTypeChange}
										placeholder={i18next.t('error_type_all')}
										className={s.selectfilterStyling}
									/>
									<SelectComponent
										key='modelType'
										isMulti={true}
										options={filterModelOptions}
										value={selectedModelType}
										onChange={handleModelTypeChange}
										placeholder={i18next.t('model_type_all')}
										className={s.selectfilterStyling}
									/>
								</div>
							</div>
						</div>
						<div className={s.filterAlign}>
							<span
								className={
									isOldestSorting ? s.sortingTypography : sortingClass.join(' ')
								}
								onClick={() => handleSortingToggle()}
							>
								{i18next.t('latest')}
							</span>
							<ToggleButton
								isOn={isOldestSorting}
								toggle={() => handleSortingToggle()}
							/>
							<span
								className={
									!isOldestSorting
										? s.sortingTypography
										: sortingClass.join(' ')
								}
								onClick={() => handleSortingToggle()}
							>
								{i18next.t('oldest')}
							</span>
							<button
								className={s.btnStyle}
								id='btnId'
								data-testid='resolveBtn'
								disabled={selectedRows.length === 0}
								onClick={() => handleResolveClick()}
							>
								{i18next.t('resolve')}
							</button>
							<List
								className={
									showGrid ? s.active_fill_color : s.inactive_fill_color
								}
								onClick={() => {
									setShowList(true);
									setSelectedRows([]);
								}}
							/>
							<div className={s.line}></div>
							<Grid
								className={
									!showGrid ? s.active_fill_color : s.inactive_fill_color
								}
								onClick={() => {
									setShowList(false);
									setSelectedRows([]);
								}}
							/>
						</div>
					</div>
					<div className={s.errorContentWrapper}>
						{!showGrid && (
							<div className={s.heightPagination}>
								<PatientGridView
									data={errorDescription}
									pageSize={pageSize}
									updateSelectedRows={updateSelectedRows}
									selectedRows={selectedRows}
									loading={props?.loading}
								/>
							</div>
						)}
						{showGrid && (
							<div>
								<PatientListView
									data={errorDescription}
									pageSize={pageSize}
									updateSelectedRows={updateSelectedRows}
									selectedRows={selectedRows}
									handleSortTypeChange={handleSortTypeChange}
									loading={props?.loading}
								/>
							</div>
						)}
					</div>
					{props?.unresolvedError?.total_rows > 0 && (
						<Pagination
							options={pageOptions}
							pageSize={pageSize}
							currentPage={currentPage}
							endIndex={pageSize}
							onPageChange={handlePageNumberChange}
							totalCount={props?.unresolvedError?.total_rows}
							handlePageSize={handlePageSizeChange}
							loading={props?.loading}
						/>
					)}
					<Modal
						className={s.confirmationModal}
						show={showResolutionModal}
						modalClosed={modalCloseHandler}
						restrictClosing={true}
					>
						<div>
							<div className={s.modalHeader}>
								<div className={s.modalHeadeerIcon}>
									<svg
										width='36'
										height='36'
										viewBox='0 0 36 36'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M17.9993 25.5C18.3498 25.5 18.6438 25.3815 18.8813 25.1444C19.1188 24.9074 19.2375 24.6136 19.2375 24.2632C19.2375 23.9127 19.119 23.6187 18.8819 23.3813C18.6449 23.1437 18.3511 23.025 18.0007 23.025C17.6502 23.025 17.3563 23.1435 17.1188 23.3806C16.8813 23.6177 16.7625 23.9114 16.7625 24.2618C16.7625 24.6123 16.881 24.9063 17.1181 25.1438C17.3552 25.3813 17.6489 25.5 17.9993 25.5ZM18.1191 19.7625C18.4397 19.7625 18.7063 19.6547 18.9188 19.4391C19.1313 19.2234 19.2375 18.9563 19.2375 18.6375V11.4C19.2375 11.0813 19.1291 10.8141 18.9122 10.5984C18.6953 10.3828 18.4265 10.275 18.1059 10.275C17.7853 10.275 17.5188 10.3828 17.3063 10.5984C17.0938 10.8141 16.9875 11.0813 16.9875 11.4V18.6375C16.9875 18.9563 17.0959 19.2234 17.3128 19.4391C17.5297 19.6547 17.7985 19.7625 18.1191 19.7625ZM18.01 33C15.9416 33 13.9979 32.6063 12.1787 31.8188C10.3596 31.0313 8.76875 29.9563 7.40625 28.5938C6.04375 27.2313 4.96875 25.6395 4.18125 23.8185C3.39375 21.9975 3 20.0517 3 17.9813C3 15.9108 3.39375 13.965 4.18125 12.144C4.96875 10.323 6.04375 8.7375 7.40625 7.3875C8.76875 6.0375 10.3605 4.96875 12.1815 4.18125C14.0025 3.39375 15.9483 3 18.0188 3C20.0892 3 22.035 3.39375 23.856 4.18125C25.677 4.96875 27.2625 6.0375 28.6125 7.3875C29.9625 8.7375 31.0313 10.325 31.8188 12.15C32.6063 13.975 33 15.9217 33 17.99C33 20.0584 32.6063 22.0021 31.8188 23.8213C31.0313 25.6404 29.9625 27.229 28.6125 28.5868C27.2625 29.9447 25.675 31.0198 23.85 31.8118C22.025 32.604 20.0783 33 18.01 33ZM18.0188 30.75C21.5563 30.75 24.5625 29.5063 27.0375 27.0188C29.5125 24.5312 30.75 21.5188 30.75 17.9813C30.75 14.4438 29.5149 11.4375 27.0446 8.9625C24.5742 6.4875 21.5594 5.25 18 5.25C14.475 5.25 11.4688 6.48515 8.98125 8.95545C6.49375 11.4258 5.25 14.4406 5.25 18C5.25 21.525 6.49375 24.5312 8.98125 27.0188C11.4688 29.5063 14.4813 30.75 18.0188 30.75Z'
											fill='#6C7DDF'
										/>
									</svg>
								</div>
								<div className={s.modalHeaderTitle}>
									{i18next.t("resolve_error's")}
								</div>
								<div
									className={s.closeButton}
									onClick={() => modalCloseHandler()}
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='36'
										height='36'
										viewBox='0 0 36 36'
										fill='none'
									>
										<path
											d='M9.42478 28.325L7.6748 26.5751L16.2498 18L7.6748 9.42502L9.42478 7.67505L17.9998 16.2501L26.5748 7.67505L28.3248 9.42502L19.7498 18L28.3248 26.5751L26.5748 28.325L17.9998 19.75L9.42478 28.325Z'
											fill='#6D6D6D'
										/>
									</svg>
								</div>
							</div>
							<div className={s.modalMessage}>
								{i18next.t('resolve_selected_errors_confirmation')}
							</div>
							<div className={s.modalBtns}>
								<button
									className={s.modalDeclineBtn}
									onClick={modalCloseHandler}
								>
									{i18next.t('no')}
								</button>
								<button
									className={s.modalConfirmBtn}
									onClick={() => handleResolveError()}
								>
									{i18next.t('yes')}
								</button>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		errorTypeOptions: state.errors.data,
		modelTypeOptions: state.models.data,
		loading: state.unresolvedErrors.loading,
		unresolvedError: state.unresolvedErrors.unresolvedErrors,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProfiles: (options, isMerge) =>
			dispatch(actions.getProfiles(options, isMerge)),
		getUnresolvedErrors: (args) => dispatch(actions.getUnresolvedErrors(args)),
		getErrors: (args) => dispatch(actions.getErrors(args)),
		getModels: (args) => dispatch(actions.getModels(args)),
		resolveDashboardErros: (args) =>
			dispatch(actions.resolveDashboardErrors(args)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(PatientErrors));
